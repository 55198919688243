.text {
	display: flex;
	flex-direction: column;
	margin: 1.5em 0;
	width: 100%;
}

.text__title {
	display: flex;
	font-size: 7vw;
	font-weight: 300;
	letter-spacing: -3px;
	line-height: 90%;
	color: $light;
	margin: 0.2em 0;
}

.text__title span {
	font-weight: 100;
	margin-right: 0.1em;
	color: $border;
}

.text__flex {
	display: flex;
	justify-content: center;
}

.text__flex .vl {
	border: 1px solid $border;
	height: auto;
	margin-right: 1em;
}

.text__flex__content {
	color: $light;
	font-weight: 300;
	font-size: 3em;
	letter-spacing: -0.03em;
}

@media (max-width: 768px) {
	.text__title {
		font-size: 3em;
	}
	.text__flex__content {
		font-size: 2.2em;
	}
}
