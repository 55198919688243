$card-width: 80vw;
$card-height: 50rem;
$transition-time: 450ms;
$transition-easing: cubic-bezier(0.165, 0.84, 0.44, 1);
$border-from: #373737;
$border-to: #606060;

.cards {
	position: relative;
	height: 100vh;
	overflow: hidden;
}

.cards__inner {
	position: relative;
	width: $card-width;
	height: $card-height;
	top: 70%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.card {
	position: absolute;
	contain: content;
	backface-visibility: hidden;
	transform: translateZ(0);
	overflow: hidden;
	padding: 20px;
	border: 3px solid transparent;
	border-radius: 0.5em;
	background: linear-gradient(to right, $boxcolor, $boxcolor),
		linear-gradient(to top, $border-from 10%, $border-to 65%, $border-from 100%);
	background-clip: padding-box, border-box;
	background-origin: padding-box, border-box;
	width: $card-width;
	height: $card-height;
	transition: top $transition-time $transition-easing,
		background-position 2s $transition-easing;
	top: 0;
}

.card__title {
	display: flex;
	font-size: 7vw;
	font-weight: 300;
	letter-spacing: -3px;
	padding: 0;
	margin: 0;
}

.card__title span {
	font-weight: 100;
	margin-right: 0.1em;
	color: $border;
}

.card:not(.expanded):hover {
	cursor: pointer;
	top: -10%;
}

.hidden {
	display: none;
}

.expanded {
	animation: expand $transition-time forwards $transition-easing;
	padding-bottom: 200px;
	.hidden {
		display: block;
	}
}

@keyframes expand {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100px);
	}
}

@media (min-height: 800px) {
	.expanded {
		height: calc($card-height + 200px);
	}

	@keyframes expand {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(-21vh);
		}
	}
}

@media (max-width: 768px) {
	.cards__inner {
		width: 100%;
	}
	.card {
		width: 100vw;
		padding: 10px;
	}
	.card__title {
		font-size: calc(25vw * 0.4); // Reduce the font size
	}
	.card:not(.expanded):hover {
		cursor: pointer;
		top: 0;
	}
}
