$navbar-width: 540px;
$navbar-height: 40px;

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar {
	width: $navbar-width;
	height: $navbar-height;
	position: fixed;
	transform: translateX(-50%);
	top: 22px;
	left: 50%;
	z-index: 1000;
	border-radius: 0.5em;
	outline: 1px solid rgba(192, 192, 192, 0.168);
	background: rgba(40, 40, 40, 0.29);
	backdrop-filter: blur(15px) saturate(180%);
	@include flex-center;

	.navbar-content {
		@include flex-center;
		justify-content: space-between;
		width: 100%;
		padding: 1px;

		.navbar-section {
			@include flex-center;

			&.logo-section {
				img {
					height: 20px;
					margin: 3px 0 0 15px;
				}
			}

			&.cta-section {
				display: flex;
				button {
					border: 1px solid $border;
					background: rgba(43, 43, 43, 0.638);
					color: $light;
					cursor: pointer;
					min-width: 115px;
					height: 37px;
					border-radius: 7px;
					text-decoration: none;
					font-size: 1.1em;
					font-weight: 400;
					letter-spacing: -0.5px;
					transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

					&:hover {
						background: rgba(200, 200, 200, 0.477);
						scale: 1.05;
						border: 3px solid rgba(200, 200, 200, 0.477);
					}
				}
			}

			&.plus-section {
				margin: 0 10px 0 0;
			}
		}

		@media (max-width: 550px) {
			width: 90vw;
			height: $navbar-height;
			justify-content: space-between;

			.navbar-section {
				width: 100%;
				height: 100%;

				&.cta-section {
					width: auto;
				}

				&.logo-section {
					width: auto;
				}
			}
		}
	}
	@media (max-width: 550px) {
		top: 0;
		.navbar-content {
			width: 90vw;
			height: $navbar-height;
			justify-content: space-between;

			.navbar-section {
				width: 100%;
				height: 100%;

				&.cta-section {
					width: auto;
				}

				&.logo-section {
					width: auto;
				}
			}
		}
	}
}

.beta-warning {
	position: fixed;
	z-index: 1000;
	bottom: 20px;
	right: 50%;
	background: rgba(255, 0, 0, 0.29);
	backdrop-filter: blur(15px) saturate(180%);
	color: red;
	border-radius: 5px;
	padding: 1px 3px;
	transform: translateX(50%);
	font-family: 'ballinger-mono', sans-serif;
	text-align: center;
	text-transform: uppercase;
	font-size: 0.7em;
}
