.list-section {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding: 3em;

	hr {
		border: 1px solid $light;
		opacity: 40%;
		margin: 0;
	}

	.list-content {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 800px;
		height: 100%;
		padding: 0 20px;

		@media (max-width: 768px) {
			width: 98vw; // Adjusted width for smaller screens
		}
	}

	.list-item {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		h3 {
			font-size: 1.3em;
			font-weight: 400;
			padding-bottom: 1em;
		}

		p {
			font-size: 1.3em;
			font-weight: 300;
			color: $light;
			opacity: 40%;

			width: 400px;

			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}

	@media (max-width: 768px) {
		padding: 0;
	}
}
