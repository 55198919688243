@import 'imports/_normal.scss';
@import 'imports/_card.scss';
@import 'imports/_newsletter.scss';
@import 'imports/_navbar.scss';
@import 'imports/_imageWrapper.scss';
@import 'imports/_text.scss';
@import 'imports/_list.scss';

body {
	background: $dark;
	color: $light;
}
