.newsletter-container {
	.newsletter-flex {
		display: flex;
		justify-content: space-between;
		padding: 2em 0;
	}

	.copyright {
		font-size: 15px;
		color: $light;
		display: flex;
		font-weight: 100;

		span {
			padding-right: 0.4em;
			color: gray;
		}
	}

	.logo-image {
		height: 5.5em;
		width: auto;
	}
}

.subscription-section {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 5.5em;
	padding: 0.3em 0;

	h3 {
		font-size: 15px;
		font-weight: 400;
		text-transform: capitalize;
	}

	form {
		display: flex;
		justify-content: space-between;

		input[type='text'],
		button {
			background: none;
			border: none;
			color: $light;
			opacity: 40%;

			&:focus,
			&:hover {
				opacity: 100%;
			}
		}
	}

	.active {
		color: $boxcolor;
		background: $light;
		border-radius: 5px;
		padding: 0 5px;
		font-weight: 900;
		opacity: 100%;
	}

	hr {
		margin-top: 0.3em;
	}
}

@media screen and (max-width: 1100px) {
	.newsletter-container {
		.logo-image {
			height: 2em;
			margin-bottom: 1em;
		}

		.newsletter-flex {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
