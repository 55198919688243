// Mixin for background gradients
@mixin background-gradient($start-color, $end-color) {
	background: linear-gradient(180deg, $start-color 0%, $end-color 100%);
}

@mixin background($location) {
	background: url($location) no-repeat center center;
	background-size: cover;
}

// Mixin for image styles
@mixin image-styles($height, $width) {
	display: flex;
	justify-content: center;
	align-items: center;
	height: $height;
	width: $width;
	overflow: hidden;
	object-fit: contain;
}

.image__container {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	margin-top: 2em;
	border-radius: 0.5em;
	overflow: hidden;

	.image__card {
		@include image-styles(100vh, 50%);

		//For 2 images
		&:not(:only-child) {
			width: 100%;

			img {
				height: 90%;
				max-width: 90%;
				object-fit: contain; // Fix: Prevent images from stretching
				max-width: clamp(
					0px,
					90%,
					100%
				); // Fix: Set maximum width for image size
				max-height: clamp(
					0px,
					90%,
					100%
				); // Fix: Set maximum height for image size
			}
		}

		&[data-bg='light'] {
			@include background-gradient(#cbcbcb, #e6e6e6);
		}

		&[data-bg='dark'] {
			@include background-gradient(#5c5a59, #756f6d);
		}

		&[data-bg='green'] {
			@include background-gradient(#dae0db, #dae0db);
		}

		&[data-bg='blue'] {
			@include background-gradient(#dadee6, #dadee6);
		}

		&[data-bg='purple'] {
			@include background-gradient(#f4f1f8, #f4f1f8);
		}

		//FULL SCREEN IMAGE

		&[data-bg='trees'] {
			@include background('../assets/stock/trees.jpg');
		}
	}

	.comment {
		position: absolute;
		padding: 0.3em 0;
		background: #dadee66b;
		backdrop-filter: blur(10px) saturate(180%);
		bottom: 0;
		width: 100%;
		font-family: 'ballinger-mono', sans-serif;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
		font-size: 0.8em;
		color: $border;
	}

	@media (max-width: 768px) {
		flex-wrap: wrap;
		margin-top: 1em;

		.card,
		.comment {
			width: 100%;
		}
	}
}
